









































































































































import {FactService} from '@/services/FactService';
import {Component, Vue, Prop} from 'vue-property-decorator';

import _ from 'lodash';
import EventBus from '@/common/EventBus';

import FactText from '@/components/common/form/fact/FactText.vue';
import FactDate from '@/components/common/form/fact/FactDate.vue';
import FactOption from '@/components/common/form/fact/FactOption.vue';
import FactMultilineText from '@/components/common/form/fact/FactMultilineText.vue';
import FactNumber from '@/components/common/form/fact/FactNumber.vue';
import FactSelection from '@/components/common/form/fact/FactSelection.vue';
import FactList from '@/components/common/form/fact/FactList.vue';
import FactLabel from '@/components/common/form/fact/FactLabel.vue';
import FactTooltip from '@/components/common/form/fact/FactTooltip.vue';
import FactAddressAutoComplete from '@/components/common/form/fact/FactAddressAutoComplete.vue';
import {State, Action, Getter} from 'vuex-class';
import Benefits from '@/components/products/Benefits.vue';
import utils from '../../../../Utils';
import Proposal from '@/models/proposal';
import Util from '@/utils/Util';
import ResponsiveTable from '@/components/common/ResponsiveTable.vue';
import FactMultiSelect from '@/components/common/form/fact/FactMultiSelect.vue';
import CustomMultiBoolean from '@/components/common/form/fact/CustomMultiBoolean.vue';
import config from '@/config';
import Product from '@/models/product';
import { IFactUpdatePayload, IAddInsuredPayload, IRemoveInsuredPayload, IMultiFactUpdatePayload, IFactObject } from '@/interfaces';
import AssetSection from '@/components/products/AssetSection.vue';

const GROUP_ROOT_FACTS = 'GROUP_ROOT_FACTS';
const NAMED_FACT_GROUPS = 'NAMED_FACT_GROUPS';
const NAMED_FACT_GROUPS_CONFIGURATION = 'NAMED_FACT_GROUPS.CONFIGURATION';
const MANUAL_FACT_GROUPS = 'MANUAL_FACT_GROUPS';
const MANUAL_FACT_GROUPS_CONFIGURATION = 'MANUAL_FACT_GROUPS.CONFIGURATION';
const TRAVEL_PRODUCTS = 'TRAVEL_PRODUCTS';
const CONDITIONAL_PRODUCTS = 'CONDITIONAL_PRODUCTS';
const ADDITIONAL_CONFIG_FACTS = 'ADDITIONAL_CONFIG_FACTS';
const OMITTED_PRICING_FACTS = 'OMITTED_PRICING_FACTS';
const ADDED_POLICY_FACTS = 'ADDED_POLICY_FACTS';
const SHIFT_ASSET_LOCATION = 'SECONDARY';
const MANUAL_FACT_GROUPS_TOOLTIP_CONFIGURATION = 'MANUAL_FACT_GROUPS.CONFIGURATION.TOOLTIP';
const OMITTED_INSURED_TYPES_CONFIGURATION = 'OMITTED_INSURED_TYPES_CONFIGURATION';
const CUSTOM_MODEL_FACTS = 'CUSTOM_MODEL_FACTS';

const FIRST_NON_TECHNICAL_ERROR = 100;
const STEP = 'configuration';

@Component({
  name: 'ProductComponentConfig',
  inject: ['$validator'],
  components: {
    FactList,
    FactLabel,
    FactTooltip,
    FactSelection,
    FactOption,
    FactDate,
    FactText,
    FactMultilineText,
    FactMultiSelect,
    CustomMultiBoolean,
    FactNumber,
    FactAddressAutoComplete,
    Benefits,
    ResponsiveTable,
    AssetSection
  },
})
export default class ProductComponentConfig extends Vue {
  @Prop() private componentIndex!: number;
  @Prop() private product!: Product;
  @Prop() private productContent!: string;
  @Prop() private priceInfo!: any;
  @State private app: any;
  @State private cms: any;
  @Prop() private data!: any;
  @Prop() private productFacts!: any[];
  @Prop() private reviewRequired!: any;
  @State private proposal!: Proposal;
  @Action('quotation/updateFact') private updateFact!: (payload: IFactUpdatePayload) => void;
  @Action('quotation/updateMultiFact') private updateMultiFact!: (payload: IMultiFactUpdatePayload) => void;
  @Action('quotation/addInsured') private addInsured!: (payload: IAddInsuredPayload) => void;
  @Action('quotation/removeInsured') private removeInsured!: (payload: IRemoveInsuredPayload) => void;
  @Getter('quotation/getFactValidationData') private getFactValidationData!: (productID: string, validatedFacts: string[]) => any[];

  private options(fact: any) {
    return FactService.getOptions(this.productContent, fact.id);
  }

  get namedFactGroups() {
    return _.merge({..._.get(this.app.config.shiftMappings, NAMED_FACT_GROUPS, {})}, {..._.get(this.app.config.shiftMappings, NAMED_FACT_GROUPS_CONFIGURATION, {})});
  }

  get manualFactGroups() {
    return _.merge({..._.get(this.app.config.shiftMappings, MANUAL_FACT_GROUPS, {})}, {..._.get(this.app.config.shiftMappings, MANUAL_FACT_GROUPS_CONFIGURATION, {})});
  }

  get tooltipManualFactGroups() {
    return _.get(this.app.config.shiftMappings, MANUAL_FACT_GROUPS_TOOLTIP_CONFIGURATION, {});
  }

  get groupRootFacts() {
    return _.get(this.app.config.shiftMappings, GROUP_ROOT_FACTS, []);
  }

  get pricingFactGroups() {
    return utils.getFactGroups(
      this.allFacts(this.data),
      this.namedFactGroups,
      this.manualFactGroups,
      this.product.code,
      this.groupRootFacts,
      ['COMPONENT:base.base'],
      false,
    );
  }

  get conditionalyAddedFacts() {
    if (_.includes(_.get(this.app.config.shiftMappings, TRAVEL_PRODUCTS, []), this.product.code.toUpperCase()) ||
      _.includes(_.get(this.app.config.shiftMappings, CONDITIONAL_PRODUCTS, []), this.product.code.toUpperCase())) {
      return _.filter(this.productFacts, (fact: any) => {
        return _.includes(_.get(this.app.config.shiftMappings, ADDITIONAL_CONFIG_FACTS, []), fact.id);
      });
    } else {
      return [];
    }
  }

  // get _() {
  //   return _;
  // }

  private groupClassName(group) {
    return group && group.name && _.camelCase(group.name);
  }

  private isEmpty(text: string) {
    return _.isEmpty(text);
  }

  private showGroup(group) {
    return (this.data.name !== _.get(group, 'root.name') &&
    !_.get(group, 'root.id', '').endsWith(this.data.code) &&
    group.header &&
    !group.facts.find((f) => f.options.length === 0 && f.type === 'LIST') ||
    this.groupRootFacts.includes(group.name)) &&
    this.getFactGroupText(group);
  }

  private getFactGroupText(group) {
    return utils.getGroupText(this, group, STEP, this.product.code, _.get(this.app, 'config.shiftMappings'));
  }

  private registerClass(fact) {
    return utils.registerClass(this.app.config.shiftMappings, fact, this.product.code);
  }

  private  assetGroupColumns(group, index) {
    const columns = _.get(this.app, `config.shiftMappings.MANUAL_FACT_GROUP_COLUMNS`, [])[group.name.replace(index, 'x')] || null;
    if (columns) {
      switch (columns) {
        case 1:
          return 'sm12';
          break;
        case 2:
          return 'sm6';
          break;
        case 3:
          return 'md4 sm6';
          break;
      }
    } else if (group.facts.length > 1) {
      return null;
    } else {
      return 'sm12';
    }
  }

  private hasShiftAssetLocation(type) {
    return type.toUpperCase() === SHIFT_ASSET_LOCATION;
  }

  private hasIgnoreInsuredType(insuredType) {
    return _.includes(_.get(this.app.config.shiftMappings, OMITTED_INSURED_TYPES_CONFIGURATION, []), insuredType.type);
  }

  private allFacts(element, isAsset?: boolean) {
    const ommitedFactIds = _.get(this.app.config.shiftMappings, OMITTED_PRICING_FACTS, []);
    const addedPolicyFactIds = _.get(this.app.config.shiftMappings, ADDED_POLICY_FACTS, []);

    const addedPolicyFacts = _.filter(element.allFacts, (f: any) => _.includes(addedPolicyFactIds, f.id.replace(/:\d+/g, ':x')));
    const additionalFacts = isAsset ? addedPolicyFacts : _.concat(this.conditionalyAddedFacts, addedPolicyFacts);
    const listedFacts = this.omitFacts(_.union(element.pricingFacts, additionalFacts), ommitedFactIds);
    const customModeledFacts = utils.customModeler(listedFacts, _.get(this.app.config.shiftMappings, CUSTOM_MODEL_FACTS, {}));

    return this.orderFacts(customModeledFacts, _.union(element.allFacts, additionalFacts));
  }

  private getAssetByType(assets: any[], type: string) {
    return _.filter(assets, (asset: any) => {
      return asset.type === type;
    });
  }

  private assetGroupsFacts(asset) {
    const groups = utils.getFactGroups(
      this.allFacts(asset, true),
      this.namedFactGroups,
      this.manualFactGroups,
      this.product.code
    );
    const realGroups = groups.filter((g) => this.hasRealFacts(g.facts));
    return realGroups;
  }

  private hasRealFacts(facts) {
    return facts.some((f) => _.get(f, 'type.type') !== 'GROUP');
  }

  private omitFacts(facts: any[], omittedIds: string[]) {
    const generalFacts = _.get(this.app, 'config.shiftMappings.GENERAL_FACTS', {})[this.app.targetSegment] || [];
    return _.filter(facts, (f: any) => {
      return !_.includes(omittedIds, f.id.replace(/:\d+/g, ':x')) && !generalFacts.includes(f.id);
    });
  }

  private orderFacts(filteredFacts, allFacts) {
    const allFactIds = _.map(allFacts, 'id');
    return _.orderBy(filteredFacts, (f: any) => {
      return _.indexOf(allFactIds, f.id);
    });
  }

  private getAssetPrice(index) {
    return _.get(this.priceInfo, 'insuredResult[' + index + '].price', '-');
  }

  private getAssetIndex(max: number, index: number, type: string) {
    return max > 1 ? ('#' + ((index || 0) + (type === 'secondary' ? 2 : 1))) : '';
  }

  private getFactGroups(facts: any[]) {
    return utils.getFactGroups(facts, this.namedFactGroups, this.manualFactGroups, this.product.code);
  }

  private hasPrice() {
    return _.get(this.priceInfo, 'price') !== undefined;
  }

  private getConfigByType(componentType: string, options: any = [], id: string = '') {
    if (componentType === 'GROUP') {
      return;
    }
    if (componentType === 'BOOLEAN') {
      return 'FactOption';
    }
    if (componentType === 'LIST' && (options && options.length) >= parseInt(config.layout.multiselectLimit, 10)) {
      return 'FactMultiSelect';
    }
    const addressAutoComplete = _.keys(_.get(this.app.config.shiftMappings, 'ADDRESS_AUTO_COMPLETE', []));
    const companyAutoComplete = _.keys(_.get(this.app.config.shiftMappings, 'COMPANY_AUTO_COMPLETE', []));

    const addressType = id && String(id).match(/\.address$/) && !_.isEmpty(addressAutoComplete) && 'ADDRESS_AUTO_COMPLETE';
    const companyType = id && !!_.find(companyAutoComplete, (exp) => String(id).match(new RegExp(exp, 'i'))) && !_.isEmpty(companyAutoComplete) && 'COMPANY_AUTO_COMPLETE';
    if (_.find(addressAutoComplete, (key) => id.endsWith(key))) {
      componentType = 'ADDRESS_AUTO_COMPLETE';
    }
    return FactService.getFactType(addressType || companyType || componentType);
  }

  private getValidators(fact: any) {
    return FactService.getValidators(fact);
  }

  private containsRegex(str: string, regexList: RegExp[]): boolean {
    return _.some(regexList, (r: RegExp) => {
      return str.match(r);
    });
  }

  private hasAddTitle(insuredType: any) {
    const translated = this.$t('proposal.configuration.addTitle.' + insuredType.type);
    return translated !== ('proposal.configuration.addTitle.' + insuredType.type);
  }

  private canAddAsset(assetType: any) {
    const assets = _.filter(_.get(this.data, 'assets', []), (asset: any) => {
      return asset.type === assetType.type;
    });
    return assets.length < assetType.maxAllowed;
  }

  private canRemoveAsset(assetType: any) {
    const assets = _.filter(_.get(this.data, 'assets', []), (asset: any) => {
      return asset.type === assetType.type;
    });
    return assets.length > assetType.minAllowed;
  }

  private addAsset(insuredType: any) {
    this.addInsured({ productID: this.product.id, type: insuredType.type });
  }

  private removeAsset(id: string, type: string) {
    this.removeInsured({ productID: this.product.id, insuredID: id, type });
  }

  private updateFn(factData: any, factValue: any, autofill: boolean = false) {
    this.updateFact({
      productID: this.product.id,
      fact: factData,
      value: factValue,
      vm: this,
      autofill
    });
  }

  private validate(this: any) {
    const componentFactIds = _.map(_.concat(this.data.pricingFacts, this.conditionalyAddedFacts), 'id');
    const assetFactIds = this.getAssetFactIds(this.data);
    const allValidatedFactIds = _.concat(componentFactIds, assetFactIds);
    if (_.isEmpty(allValidatedFactIds)) {
      return true;
    } else {
      const shiftResult: any[] = this.getFactValidationData(this.product.id, allValidatedFactIds);
      if (!_.isEmpty(shiftResult)) {
        _.forEach(shiftResult, (err: any) => {
          const message = err.error.code < FIRST_NON_TECHNICAL_ERROR ? this.$t('validations.shift.' + err.error.message) : err.error.message;
          for (const factId of err.error.relatedFacts) {
            const field = Util.generateUid(err.productId, factId);
            this.$addError(field, message);
          }
        });
        return false;
      }
      return true;
    }
  }

  private getAssetFactIds(data: any) {
    let factIds: any[] = [];
    _.forEach(data.insuredTypes, (insuredType: any) => {
      _.forEach(this.getAssetByType(this.data.assets, insuredType.type), (asset: any) => {
        const assetFactIds = _.map(this.omitFacts(asset.pricingFacts, _.get(this.app.config.shiftMappings, OMITTED_PRICING_FACTS, [])), 'id');
        factIds = _.concat(factIds, assetFactIds);
      });
    });
    return factIds;
  }

  private getGroupClass(group: any) {
    const numbersOnly = ! group.facts.some((f) => f.type !== 'NUMBER');
    if (numbersOnly) {
      if (group.facts.length === 1) {
        return ['md12'];
      }
      return ['md4', 'sm6'];
    }
    if (_.get(group, 'facts.length', 0) === 2) {
      if (group.facts[1].childOf === group.facts[0].id) {
        return ['md6', 'sm6', 'half-width'];
      }
    }
    if (_.get(group, 'facts.length', 0) > 1) {
      const columns = _.get(this.app, `config.shiftMappings.MANUAL_FACT_GROUP_COLUMNS.${group.name}`, null);
      if (columns) {
        return `md${Math.floor(12 / columns)}`;
      }
    }
    return 'md12';
  }

  private getGroupSingleFact(group: any) {
    if (group.facts.length === 1) {
      return group.facts[0];
    }
    return null;
  }

  private hasSingleFactDescription(group: any) {
    const fact = group.header && this.getGroupSingleFact(group);
    return !_.isEmpty(fact) && !_.isEmpty(fact.description);
  }

  private getPricedBenefit() {
    let priced: any = null;
    if (this.priceInfo && this.priceInfo.componentResult) {
      priced = this.priceInfo.componentResult[this.componentIndex].benefits;
    }
    return _.map(this.data.benefits, (benefit: any) => {
      return {...benefit, price: _.find(priced, {id: benefit.id})};
    });
  }

  private hasBenefits(benefits) {
    return _.get(benefits, 'length');
  }

  private selectedAddress(productId: string, originatingFactId: string, address: any) {
    const fields = utils.getAddressFields(originatingFactId, address, _.get(this.app.config.shiftMappings, 'ADDRESS_AUTO_COMPLETE', {}));
    const factsDataToUpdate: any[] = [];

    fields.forEach((fact) => {
      let val = fact.val[0];
      // handler for list search val / name
      let realFact: any = null;
      _.forEach(_.values(this.allFacts(this.data)), (f) => {
        if (f.id === fact.id || f.id.endsWith(fact.id)) {
          realFact = f;
          return false;
        }
      });
      if (realFact && realFact.formatType === 'list') {
        const option = _.find(realFact.options, (opt) => _.values(fact.val).includes(opt.value) || _.values(fact.val).includes(opt.name));
        if (option) {
          val = option.value;
        }
      }
      if (realFact) {
        factsDataToUpdate.push({
          factID: realFact.id,
          factValue: val
        });
      }
    });
    this.proposal.products.forEach((product) => {
      this.updateMultiFact({
        productID: product.id,
        productCode: product.code,
        facts: factsDataToUpdate
      });
    });
  }
}
