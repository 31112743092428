








































































import {FactService, SHIFT_UPDATED, SHIFT_APPROVED, SHIFT_REJECTED} from '@/services/FactService';
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {State, Getter, Action} from 'vuex-class';
import _ from 'lodash';
import FactSelection from '@/components/common/form/fact/FactSelection.vue';
import FactPhoneNumber from '@/components/common/form/fact/FactPhoneNumber.vue';
import FactAgree from '@/components/common/form/fact/FactAgree.vue';
import FactText from '@/components/common/form/fact/FactText.vue';
import {CMSContentService} from '@/services/CMSService';
import PolicyService from '@/services/PolicyService';
import Benefits from '@/components/products/Benefits.vue';
import Breakdown from '@/components/products/Breakdown.vue';
import Util from '@/utils/Util';
import ProductEngineUtils, { BUSINESS_SECTION_NAME, USE_POLICY } from '@/utils/ProductEngineUtils';
import FactTooltip from '@/components/common/form/fact/FactTooltip.vue';
import EventBus from '@/common/EventBus';
import {IApp, IAuth, IFactUpdatePayload, IProposal, IQuotation, IQuotationProduct, IShiftResult} from '@/interfaces';
import Illustration from '@/components/common/Illustration.vue';
import GAUtil from '@/utils/GAUtil';
import WorkflowService from '@/services/WorkflowService';
import utils from '@/views/auth/Portal/PortalStepperItems/Utils';
import PEClientJs from 'product-engine-client-js';
import PEAxiosInstance from '@/utils/PEAxiosInstance';
import envConfig from '@/config';

const SUBMISSION_TIMEOUT = 30 * 1000;
const FIRST_NON_TECHNICAL_ERROR = 100;

@Component({
  name: 'Summary',
  $_veeValidate: {
    validator: 'new'
  },
  components: {
    FactTooltip,
    FactSelection,
    Benefits,
    FactText,
    FactPhoneNumber,
    FactAgree,
    Breakdown,
    Illustration
  }
})
export default class Summary extends Vue {

  public hasPayments: any = [];

  @State private app!: IApp;
  @State private cms: any;
  @State private auth!: IAuth;
  @State private proposal!: IProposal;
  @State private quotation!: IQuotation;
  @Prop() private componentIndex!: number;
  @Prop() private next: any;
  @Prop() private img: any;
  @Prop() private afterUW!: boolean;

  @Getter('proposal/getSelectedProducts') private getSelectedProducts: any;
  @Getter('proposal/getInvoicesTotalTax') private getInvoicesTotalTax: any;



  @Getter('quotation/getFactByFactID') private getFactByFactID!: (factID: string) => any;

  @Getter('quotation/getFactCMSFormatted') private getFactCMSFormatted!: (factID: string) => string;
  @Getter('quotation/getConfigFactTypeByFactType') private getConfigFactTypeByFactType!: (factID: string) => string;


  @Getter('quotation/getProductPriceWithType') private getProductPriceWithType!: (productID: string, priceType: string, isAfterUW: boolean) => number;

  @Getter('quotation/getCoverageDetailFacts') private getCoverageDetailFacts!: () => any;

  @Getter('quotation/isAllQuoteValid') private isAllQuoteValid!: () => boolean;
  @Getter('quotation/getSortedQuotationProduct') private getSortedQuotationProduct!: () => IQuotationProduct[];
  @Getter('quotation/hasUnderwritingFactsByProductID') private hasUnderwritingFactsByProductID!: (productID: string) => boolean;
  @Getter('quotation/getUnderwritingFactsByProductID') private getUnderwritingFactsByProductID!: (productID: string) => any;
  @Getter('quotation/getFactValidationData') private getFactValidationData!: (productID: string, validatedFacts: string[]) => any[];
  @Action('quotation/setQuotation') private setQuotation: any;
  @Action('app/setLoadingText') private setLoadingText: any;
  @Action('proposal/setPaymentSkipped') private setPaymentSkipped: any;
  @Action('proposal/setNoPayment') private setNoPayment: any;
  @Action('proposal/submitQuotes') private submitQuotes: any;
  @Action('proposal/reset') private proposalReset: any;
  @Action('proposal/setProductPolicyNumber') private setProductPolicyNumber: any;
  @Action('proposal/setProductPolicyId') private setProductPolicyId: any;
  @Action('proposal/setProposalInvoiceId') private setProposalInvoiceId: any;
  @Action('proposal/setInvoices') private setInvoices: any;
  @Action('proposal/setRefund') private setRefund: any;
  @Action('proposal/setIsEndorsment') private setIsEndorsment: any;
  @Action('proposal/resetSaveProposalAttempts') private resetSaveProposalAttempts: any;
  @Action('proposal/setProposalNumber') private setProposalProposalId: any;
  @Action('proposal/setReviewed') private setReviewed: any;
  @Action('proposal/setConverted') private setConverted: any;
  @Action('proposal/setQuoted') private setQuoted: any;
  @Action('proposal/setChangeAfterReview') private setChangeAfterReview: any;
  @Action('app/setPardotAvailability') private setPardotAvailability: any;
  @Action('app/resetRenewalResponse') private resetRenewalResponse: any;
  @Action('app/setProposalNumber') private setAppProposalNumber: any;
  @Action('quotation/updateFact') private updateFact!: (payload: IFactUpdatePayload) => void;
  @Action('quotation/setShiftResult') private setShiftResult!: (shiftResults: IShiftResult[]) => void;
  @Action('quotation/updateRenewalQuote') private updateRenewalQuote: any;
  @Action('app/getQuotes') private getQuotes: any;
  @Action('app/setBundleId') private setBundleId: any;
  @Action('app/setPolicyIds') private setPolicyIds: any;
  @Action('quotation/reset') private resetQuotation: any;
  @Action('app/setValue') private setValue: any;

  private noVerificationRequired = true;
  private problemFactMessages: string[] = [];

  get uniqueFacts() {
    return this.getCoverageDetailFacts();
  }

  get products() {
    return this.getSortedQuotationProduct();
  }

  get isValidToSubmit() {
    if (this.afterUW) {
      return true;
    } else {
      const isValid = this.isAllQuoteValid();

      // pop up technical error for invalid
      if (!isValid) {
        this.$dialog.open({ type: 'technical-issue', info: 'Invalid product config' });
      }

      return isValid;
    }
  }

  get productIcons() {
    const productIcons = {};
    _.forEach(this.cms.products, (product) => {
      productIcons[product.code] = product.fields.iconInverted;
    });
    return productIcons;
  }

  private declarationMissing = false;
  private shiftMessage = '';
  private shiftWarningProducts: any[] = [];
  private deadline = 0;
  private pendingPolicies = {};
  private refunds = 0;
  private refundsTax = 0;
  private loadingStageMessageCode = {
    SUBMITTING: {
      code: 'SUBMITTING',
      message: 'product.summary.loading.submitting'
    },
    CONVERTING: {
      code: 'CONVERTING',
      message: 'product.summary.loading.converting'
    },
    TIMEOUT: {
      code: 'TIMEOUT',
      message: 'product.summary.loading.timeout'
    },
    PRODUCT: {
      code: 'PRODUCT',
      message: 'product.summary.loading.product'
    }
  };
  private submissionTimeout = 1000;
  private cmsLoadTimeout = 3000;
  private declarationFacts: any[] = [];
  private processDeclarationFacts: any = null;
  private pardotSent: boolean = false;

  private shiftWarnings: any = [];

  public validate(this: any) {
    if (this.quotation.shiftResults.length > 0 && this.hasPayments.length > 0) {
      return this.next();
    }

    this.$resetErrors();
    this.declarationMissing = false;
    this.$validator.validate().then((result: any) => {

      let hasShiftErrors = false;
      for (const product of this.products) {
        const usedFactIds = this.getUsedFactIds(product);
        const shiftResult = this.getFactValidationData(product.id, usedFactIds);

        for (const err of shiftResult) {
          if (err.error.code === 1 && Util.getDeclarationFacts().includes(err.error.relatedFacts[0])) {
            continue; // Declaration is on Summary page
          }
          hasShiftErrors = true;
          const factCode = Util.generateUid(err.productId, err.error.relatedFacts[0]);
          const message = err.error.code < FIRST_NON_TECHNICAL_ERROR ? this.$t('validations.shift.' + err.error.message) : err.error.message;
          this.$addError(factCode, message);
        }
        const missing = _.get(product, 'underwriting.factsMissing', []);
        for (const m of missing) {
          const factCode = Util.generateUid(product.code, m);
          const message = this.$t('validations.shift.REQUIRED_FIELD');
          this.$addError(factCode, message);
        }
      }


      if ((result === true || result.valid === true) && !hasShiftErrors && this.isValidToSubmit) {
        this.$global.leaveAnimation();
        this.submit();
      } else {
        this.$emit('error', this.xxErrors);
      }

      // if (result && this.isValidToSubmit) {
      //   this.submit();
      // } else {
      //   this.declarationMissing = true;
      //   this.$emit('error', this.xxErrors);
      // }
    });
  }

  public getQuote(this: any) {
    this.$resetErrors();
    this.declarationMissing = false;
    this.$validator.validate().then((result: any) => {
      if (result && this.isValidToSubmit) {
        const productId = this.proposal.products[0].id;
        const quote = this.quotation.products[productId];
        const config = quote.storedSession;
        WorkflowService.runWorkflowSync(
          'dom-get-quote',
          { productId, productConfig: config },
          ['underwriting', 'pricing']
        ).then((response) => {
          const underwriting = response.data.data.underwriting;
          const pricing = response.data.data.pricing;
          this.setQuoted(true);
          this.setQuotation({underwriting, pricing});
          this.$dialog.open({type: 'price-change'});
        });
      } else {
        this.declarationMissing = true;
        this.$emit('error', this.xxErrors);
      }
    });
  }

  private hasUnderwritingQuestions(productId: string) {
    const hasQuestions = this.hasUnderwritingFactsByProductID(productId);
    const genericFactIds = utils.getGenericFactsConfig('verification.facts') || [];

    if (hasQuestions || genericFactIds.length > 0) {
      this.noVerificationRequired = false;
    }
    return hasQuestions;
  }

  private getUsedFactIds(product: any) {
    const facts = this.getUnderwritingFactsByProductID(product.id);
    return _.map(facts, 'id');
  }

  private getFactLevel(fact) {
    if (fact._level === undefined) {
      const id = _.get(fact, 'id', '');
      fact._level = (id.match(/\./g) || '').length;
    }
    return fact._level;
  }

  private getFactUID = (productId: string, factId: string) => Util.generateUid(productId, factId);

  private getFactUpdateFn(productId: string) {
    return (factData: any, factValue: any) => {
      this.updateFact({
        productID: productId,
        fact: factData,
        value: factValue,
        noSynonym: true
      });
    };
  }

  private submit() {
    this.sendToCRM();
    this.updateLoadingStage(this.loadingStageMessageCode.SUBMITTING.code);
    this.getQuotesAndPolicies();
  }

  private created() {
    this.processDeclarationFacts = _.debounce(this.processDeclarationFactsFunc, this.cmsLoadTimeout, {
      leading: true,
      trailing: false
    });
    EventBus.$on('TreeUpdated', _.debounce((id) => {
      EventBus.$emit('TreeUpdated-Summary', id);
    }, Util.RE_RENDER_DEBOUNCE, Util.RE_RENDER_DEBOUNCE_OPTIONS));
    this.initStep();
    this.resetSaveProposalAttempts();
  }

  private mounted() {
    this.submit = _.debounce(this.submit, this.submissionTimeout, {
      leading: true,
      trailing: false
    });
    if (this.app.isContinuation) {
        GAUtil.ODLogContinuation();
    }
  }

  private updated() {
    if (!this.pardotSent && this.contactEmail && this.contactPhone && this.app.isContinuation) {
      if (this.app.originUrl) {
        const pardotObj = {
          email: this.contactEmail,
          mobile: this.contactPhone,
          ContinuationLink: encodeURIComponent(this.app.originUrl),
          ApplicationStatus: 'CONTINUATION_LINK_ACCESSED'
        };
        this.reportContinuationUsed(pardotObj);
        this.pardotSent = true;
        GAUtil.ODLogContinuation();
      }
    }
  }

  private async reportContinuationUsed(pardotObj: any) {
    _.get(await this.$pardot(), Util.PARDOT_ACTIONS.REPORT_CONTINUATION_ACCESSED, Util.PARDOT_ACTIONS.PLACEHOLDER_METHOD)(pardotObj);
  }

  private activated() {
    this.initStep();
    this.$global.enterAnimation();
    Util.gaLogPageView(this, `/summary+${sessionStorage.subSegment ? sessionStorage.subSegment : sessionStorage.targetSegment}`);
    this.$nextTick(() => {
      EventBus.$emit('stepper-idle');
    });
    // EventBus.$on('TreeUpdated-Summary', _.debounce((id) => {
    // }));
    GAUtil.ODLogSummary();
  }

  private deactivated() {
    EventBus.$emit('stepper-busy');
    EventBus.$off('TreeUpdated-Summary');
  }

  private initStep() {
    if (this.afterUW) {
      this.checkRefunds();
    }
    this.processDeclarationFacts();
  }

  private async processDeclarationFactsFunc() {
    const facts: any[] = [];
    for (const id of Util.getDeclarationFacts()) {
      if (id) {
        const fact = await this.getFactCMSFormatted(id);
        if (fact) {
          facts.push(fact);
        }
      }
    }
    this.declarationFacts = facts;
  }

  get contactInfoFacts() {
    return this.contactMobileFact && this.contactEmailFact ?
       [this.contactEmailFact, this.contactMobileFact] : [];
  }

  get contactMobileFact() {
    const factId = Object.keys(this.app.contactInfoFacts).filter((key) => key.endsWith('phone'))[0];
    return this.getFactByFactID(factId);
  }

  get contactEmailFact() {
    const factId = Object.keys(this.app.contactInfoFacts).filter((key) => key.endsWith('email'))[0];
    return this.getFactByFactID(factId);
  }

  get contactPhone() {
    return this.proposal.contactInfo.phone;
  }

  get contactEmail() {
    return this.proposal.contactInfo.email;
  }

  get getErrorMessage() {
    return this.shiftMessage;
  }

  get getWarningMessage() {
    const message = this.shiftMessage;
    const products = this.shiftWarningProducts;
    let title: string = '';
    _.forEach(products, (product: any, index: number) => {
      title += this.getProductTitle(product) + (index < products.length - 2 ? ', ' :  index < products.length - 1 ? ' and ' : '');
    });
    return message.replace('{0}', `<b>${title}</b>`);
  }

  get getContinueAsMainButton() {
    const continueAsMainButton = {
      type: 'main',
      text: this.$t('button.continue'),
      onClick: () => {
        EventBus.$emit('close-loading-dialog');
        this.getQuotesAndPolicies();
      }
    };
    return continueAsMainButton;
  }

  get getProceedCatalogueButton() {
    const proceedCatalogueButton = {
      text: this.$t('button.proceedCatalogue'),
      onClick: this.acknowledgeShiftError
    };
    return proceedCatalogueButton;
  }

  get getCntOrProCatButtons() {
    if (this.isDom()) {
      return [this.getContinueAsMainButton];
    } else {
      return [this.getContinueAsMainButton, this.getProceedCatalogueButton];
    }
  }

  private getProratedPrice(id: any) {
    return this.getProductPriceWithType(id, 'totalPrice', this.afterUW);
  }

  private getProductTitle(product: IQuotationProduct) {
    const pp = _.find(this.getSelectedProducts(), (fp: any) => fp.code === product.code);
    return pp && pp.productTitle;
  }

  private async checkRefunds() {
    const response = await PolicyService.getBundleStatus(this.app.bundleId).catch((e: any) => {
      if (e.code !== 404) {
        console.error(e.message || e);
      }
    });
    const invoiceId = _.get(response, 'data.data.invoiceId');
    if (!invoiceId) {
      return;
    }
    const type = _.get(response, 'data.data.type');
    this.setProposalInvoiceId(invoiceId);
    const invoiceStatus = await PolicyService.getInvoiceStatus(invoiceId).catch((e: any) => {
      console.error(e.message || e);
    });
    this.setInvoices(_.get(invoiceStatus, 'data.data', []).filter((i) => i.entryType === 'DEBIT'));

    const credits = _.get(invoiceStatus, 'data.data', []).filter((i) => i.entryType === 'CREDIT');
    let refunds = 0;
    let refundsTax = 0;
    credits.forEach((i) => {
      refunds += i.amount;
      refundsTax += i.taxAmount;
    });
    this.refunds = -refunds || 0;
    this.refundsTax = -refundsTax || 0;
    this.setRefund({
      refund: this.refunds,
      refundTax: this.refundsTax
    });

    if (type === 'ENDORSMENT') {
      this.setIsEndorsment(true);
    }
  }

  private async refreshRenewal() {
    this.resetRenewalResponse();
    await this.getQuotes(this.app.proposalId).catch((e: any) => console.error(e));
  }

  private async getQuotesAndPolicies() {
    if (this.proposal.reviewed) {
      this.setReviewed(false);
      // if there is changes after review, create a new proposal.
      if (this.proposal.changeAfterReview) {
        this.setAppProposalNumber('');
        this.setProposalProposalId('');
        this.setConverted(false);
      } else {
        this.finish();
        return;
      }
    }

    if (this.afterUW) {
      for (const policyId of this.app.policyIds) {
        const statusResponse = await PolicyService.getPolicyStatus(policyId).catch((e: any) => {
          if (e.code !== 401) {
            this.shiftUnavailable();
          }
        });
        if (statusResponse === '401') {
          setTimeout(this.getQuotesAndPolicies, 1000);
          return;
        }
        if (statusResponse && statusResponse.status === 200) {
          const p = _.get(statusResponse, 'data.data', {});
          this.pendingPolicies[p.id] = {
            policyNumber: p.policyNumber,
            productId: p.productId,
            status: p.status,
            config: p.config
          };
          this.setProductPolicyId({productId: p.productId, policyId: p.id});
        }
      }
      return this.checkResults();
    }

    if (this.app.isRenewal && this.app.proposalId) {
      await this.getQuotes(this.app.proposalId);
      await this.updateRenewalQuote();
    }

    // if proposal is not converted and not created, create a proposal.
    if (!this.proposal.converted && (!this.proposal.proposalId || this.proposal.saveProposalAttempts > 0)) {
      const response = await this.submitQuotes()
        .catch((e: any) => {
          if (e.code !== 401 && e.code !== 412) {
            this.shiftUnavailable();
          }
      });
      if (response === '401') {
        setTimeout(this.getQuotesAndPolicies, 1000);
        return;
      }
      if (response === '412') {
        if (this.app.isRenewal) {
          await this.refreshRenewal();
        }
        setTimeout(this.getQuotesAndPolicies, 1000);
        return;
      }
    }

    // if created but not converted, convert the proposal.
    if (this.proposal.proposalId && !this.proposal.converted) {
      const quotes: string[] = [];
      const selectedParties: any = {};
      _.each(this.proposal.createdQuotes, (createdQuote: any) => {
        if (createdQuote.id) {
          quotes.push(createdQuote.id);
        }
      });
      let convertResponse;
      if (this.app.savedProposal && this.app.savedProposal.policyPartyType && this.app.savedProposal.policyPartyId) {
        selectedParties[this.app.savedProposal.policyPartyType] = this.app.savedProposal.policyPartyId;
      }
      convertResponse = await PolicyService.convertPolicy({quotes, selectedParties})
      .catch((e: any) => {
        if (e.code !== 401 && e.code !== 412) {
          this.shiftUnavailable();
        }
      });
      if (convertResponse === '401') {
        setTimeout(this.getQuotesAndPolicies, 1000);
        return;
      }
      if (convertResponse === '412') {
        if (this.app.isRenewal) {
          await this.refreshRenewal();
        }
        setTimeout(this.getQuotesAndPolicies, 1000);
        return;
      }
      if (convertResponse && convertResponse.status === 200) {
        this.setConverted(true);
        this.pendingPolicies = [];
        const policies = _.get(convertResponse, 'data.data.policies', []);
        for (const p of policies) {
          this.pendingPolicies[p.id] = {policyNumber: p.policyNumber, productId: p.productId, status: p.status, config: p.config};
          this.setProductPolicyId({productId: p.productId, policyId: p.id});
        }
        this.setBundleId(_.get(convertResponse, 'data.data.bundle.id'));
      }
    }
    if (this.proposal.converted) {
      const bundleResponse = await PolicyService.getBundleStatus(this.app.bundleId).catch((e: any) => {
        if (e.code !== 401) {
          this.shiftUnavailable();
        }
      });
      if (bundleResponse === '401') {
        setTimeout(this.getQuotesAndPolicies, 1000);
        return;
      }
      if (bundleResponse && bundleResponse.status === 200) {
        // reset deadline if it exists
        this.deadline = 0;
        this.checkBundleStatus(bundleResponse);
      }
    }
  }

  private async checkBundleStatus(response: any) {
    let timeIsUp = false;
    if (this.deadline) {
      const now = +new Date();
      if (now > this.deadline) {
        timeIsUp = true;
      } else {
        const timeout = +new Date() + (10 * 1000);
        if (timeout > this.deadline) {
          this.updateLoadingStage(this.loadingStageMessageCode.TIMEOUT.code);
        } else {
          this.updateLoadingStage(this.loadingStageMessageCode.CONVERTING.code);
        }
      }
    } else {
      this.deadline = +new Date() + +_.get(this.app, 'config.timeouts.submission', SUBMISSION_TIMEOUT);
    }
    const status = _.get(response, 'data.data.status');
    if (status === 'CREATED' || status === 'FINISHING') {
      if (!timeIsUp) {
        const newResponse = await PolicyService.getBundleStatus(_.get(response, 'data.data.id'))
          .catch((e: any) => {
            console.error(e.message || e);
          });
        if (newResponse && newResponse.status === 200) {
          setTimeout(this.checkBundleStatus.bind(this, newResponse), 1000);
        } else {
          // in case of error on interface response is not present, we need to simulate to continue with flow
          const dummyResponse = {
            data:
              { data:
                  {
                    status: 'CREATED',
                    id: _.get(response, 'data.data.id')
                  }
              }
          };
          setTimeout(this.checkBundleStatus.bind(this, dummyResponse), 1000);
        }
      } else {
        this.timeIsUpHandler();
      }
    } else if (status === 'COMPLETE') {
      const invoiceId = _.get(response, 'data.data.invoiceId');
      this.setProposalInvoiceId(invoiceId);
      // add invoices
      const invoiceStatus = await PolicyService.getInvoiceStatus(invoiceId).catch((e: any) => {
       console.error(e.message || e);
      });
      this.setInvoices(_.get(invoiceStatus, 'data.data', []).filter((i) => i.entryType === 'DEBIT'));

      this.checkResults(_.get(response, 'data.data.id'));
    } else {
      this.shiftUnavailable();
    }
  }

  private updateLoadingStage(stage: string, productName?: any) {
    this.setLoadingText({message: this.$t(this.loadingStageMessageCode[stage].message), productName});
  }

  private resetLoadingStage() {
    this.setLoadingText(null);
  }

  private async checkResults(bundleId?: string) {
    this.hasPayments = [];
    this.shiftWarnings = [];
    const warnings = [];
    this.clearProblemFactsMessages();
    for (const id in this.pendingPolicies)  {
      if (!this.pendingPolicies.hasOwnProperty(id)) {
        continue;
      }
      const pId = this.pendingPolicies[id].productId;
      const quotedProduct = this.quotation.products[pId];
      this.updateLoadingStage(this.loadingStageMessageCode.PRODUCT.code, quotedProduct.product.name);

      const newStatus = await PolicyService.getPolicyStatus(id).catch((e: any) => {
        console.error(e.message || e);
      });
      if (!newStatus || newStatus.status !== 200) {
        return this.shiftUnavailable();
      }

      const p = _.get(newStatus, 'data.data', {});
      const originalPrice = this.getProratedPrice(p.productId);
      if (p.status === 'PENDING_PAYMENT' || p.status === 'PENDING_PAYMENT_REVIEWED' || p.status === 'PENDING_PAYMENT_UNLAPSED' || p.status === 'LAPSING' ) {
        const newPrice = _.get(quotedProduct.customDescription, 'calculation.price.totalPrice');
        this.hasPayments.push(quotedProduct);
        this.setProductPolicyNumber({productId: p.productId, policyNumber: p.policyNumber});
        // ignore price comparison for DOM-LAN.
        if (originalPrice !== newPrice && quotedProduct.code !== 'DOM-LAN') {
          this.shiftWarnings.push({product: quotedProduct, message: originalPrice ? SHIFT_UPDATED : SHIFT_APPROVED});
          this.setReviewed(true);
          this.setChangeAfterReview(false);
        }
      } else if ((p.status === 'MANUAL_PRICING' || p.status === 'MANUAL_UNDERWRITING') && _.isFinite(originalPrice)) {
        this.shiftWarnings.push({product: quotedProduct, message: SHIFT_REJECTED});
      } else {
        this.setProductPolicyNumber({productId: p.productId, policyNumber: p.policyNumber});
      }
      await this.retrieveProblemFacts(p.id.toString(), p.productId.toString(), p.config.toString());
    }
    this.resetLoadingStage();

    this.setShiftResult(_.cloneDeep(this.shiftWarnings));

    if (this.proposal.reviewed) {
      this.setPolicyIds(_.keys(this.pendingPolicies));
      setTimeout(() => {
        EventBus.$emit('policy:reload');
      }, 500);
    }

    this.acknowledgeShiftWarning();
  }

  private clearProblemFactsMessages() {
    this.problemFactMessages = [];
  }

  private async retrieveProblemFacts(policyId: string, productId: string, policyConfig: string) {

    const engine = new PEClientJs.ProductEngine({
      axiosInstance: PEAxiosInstance,
      productId,
      policyId,
      config: policyConfig,
      baseUrl: window.location.origin + envConfig.api.publicPath
    }) as any;

    engine.addCalloutContext('policyId', policyId);

    const underwriteDetailed = await engine.underwriteDetailed();

    for (const problemFact of underwriteDetailed.problemFacts) {
      if (problemFact.description) {
        this.problemFactMessages.push(problemFact.description);
      }
    }
  }

  private finish() {
    this.resetLoadingStage();
    this.resetRenewalResponse();
    this.setAppProposalNumber('');
    this.setProposalProposalId('');
    if (this.hasPayments.length > 0) {
      this.next();
    } else {
      this.setPaymentSkipped(true);
      this.setNoPayment(true);
      Util.gaLogPaymentEvent(this, 'payment', Util.GA_PAYMENT_STATUSES_TYPE.SUCCESS, Util.GA_PAYMENT_STATUSES_RESULT.NOTHING_TO_PAY);

      this.displaySuccessMessage();
    }
  }

  private getSuccessDialogMessage() {
    if (this.problemFactMessages.length > 0) {
      return this.problemFactMessages[0];
    } else {
      const revenueId = 'COMPONENT:base.base.revenue';
      const empty: any[] = [];

      const fact = this.products
          .reduce((index, product) => product.customAllFacts as any, empty)
          .find((f) => f.id === revenueId);

      return fact?.currentValue > 250000000
          ? this.$t('confirmation.complete.revenue-over-250-million')
          : this.$t('confirmation.complete.revenue-under-250-million');
    }
  }

  private displaySuccessMessage() {
    const message = this.getSuccessDialogMessage();

    const dialogData = {
      persistent: true,
      icon: _.get(this.cms, 'theme.approved'),
      text: message,
      buttons: [{
        type: 'main',
        text: this.$t('button.ok') || 'Ok',
        onClick: () => {
          this.goToLandingPage();
        }
      }]
    };

    this.$dialog.open(dialogData);
  }

  private goToLandingPage() {
    EventBus.$emit('cleanup-journey', () => {
      EventBus.$emit('init-app', () => {
        this.proposalReset();
        this.resetQuotation();
        this.setValue({code: 'isSavedProposal', value: false});
        this.$router.push({name: 'landing', params: {skipConfirm: 'true'}});
      });
    });
  }

  private shiftWarning(products: any[], code: string, end: boolean = false) {
    const self = this;
    CMSContentService.getStaticText(code, this.app.language).then((response) => {
      self.shiftWarningProducts = products;
      self.shiftMessage = _.get(response, 'fields.content');
      this.$dialog.open({
        icon: _.get(this.cms, 'theme.successIcon'),
        text: this.getWarningMessage,
        buttons: [
          {
            type: 'main',
            text: this.$t('button.ok'),
            onClick: end ? this.finish : this.acknowledgeShiftWarning
          }
        ],
        persistent: true
      });
    });
  }

  private timeIsUpHandler() {
    this.resetLoadingStage();
    this.deadline = 0;
    CMSContentService.getStaticText('SUBMISSION-TIMEOUT', this.app.language)
            .then((response) => {
              this.shiftMessage = _.get(response, 'fields.content');
              this.$dialog.open({
                icon: _.get(this.cms, 'theme.errorIcon'),
                text: this.shiftMessage,
                buttons: this.getCntOrProCatButtons
              });
            });
  }

  private shiftUnavailable() {
    this.resetLoadingStage();
    this.deadline = 0;
    CMSContentService.getStaticText('SHIFT-UNAVAILABLE', this.app.language)
      .then((response) => {
        this.shiftMessage = _.get(response, 'fields.content') || this.$t('technical.message');
        this.$dialog.open({
          icon: _.get(this.cms, 'theme.errorIcon'),
          text: this.getErrorMessage,
          buttons: [
            {
              text: this.$t('button.closeDialog'),
              onClick: () => {
                EventBus.$emit('close-loading-dialog');
              }
            },
            {
              type: 'main',
              text: this.$t('button.proceedCatalogue'),
              onClick: this.acknowledgeShiftError
            }
          ]
        });
      });
  }

  private acknowledgeShiftError() {
    this.$router.push({name: 'landing', params: {skipConfirm: 'true'}});
  }

  private acknowledgeShiftWarning() {
    const self = this;
    if (this.shiftWarnings.length > 0) {
      const shiftRejects = _.filter(this.shiftWarnings, (warning) => warning.message === SHIFT_REJECTED);
      if (shiftRejects.length > 0) {
        const products = _.map(shiftRejects, (warning) => warning.product);
        setTimeout(() => self.shiftWarning(products, SHIFT_REJECTED), 400);
        this.shiftWarnings = _.filter(this.shiftWarnings, (warning) => warning.message !== SHIFT_REJECTED);
      } else {
        const warning = this.shiftWarnings.shift();
        setTimeout(() => self.shiftWarning([warning.product], warning.message), 400);
      }
      this.$dialog.close();
    } else if (!this.proposal.reviewed) {
      if (this.quotation.shiftResults.length > 0 && this.hasPayments.length > 0) {
        this.$dialog.close();
        setTimeout(() => self.shiftWarning(this.hasPayments, 'CONTINUE-PAYMENT', true), 400, true);
      } else {
        this.finish();
      }
    } else {
      this.$dialog.close();
    }
  }

  private isDom() {
    // only support one product.
    if (this.proposal
      && this.proposal.products
      && this.proposal.products.length > 0
      && this.proposal.products[0].code === 'DOM-LAN') {
      return true;
    }
    return false;
  }

  private getConfigByType(componentType: string, id?: string) {
    return id && _.keys(_.get(this.app.config.shiftMappings, 'AGREEMENT_FACTS', {})).includes(id) ? 'FactAgree' : this.getConfigFactTypeByFactType(componentType);
  }

  private getValidators(fact: any) {
    return FactService.getValidators(fact);
  }

  private async sendToCRM() {
    const businessSectionFacts = this.uniqueFacts[BUSINESS_SECTION_NAME];

    try {
      await _.get(await this.$pardot(), Util.PARDOT_ACTIONS.REPORT_VERIFICATION, Util.PARDOT_ACTIONS.PLACEHOLDER_METHOD)(businessSectionFacts);
      await _.get(await this.$pardot(), Util.PARDOT_ACTIONS.REPORT_SUBMISSION, Util.PARDOT_ACTIONS.PLACEHOLDER_METHOD)(!this.declarationMissing, businessSectionFacts);
      this.setPardotAvailability(true);
    } catch {
      this.setPardotAvailability(false);
    }

    if (this.app.isRenewal && this.app.originUrl) {
      _.get(await this.$pardot(), Util.PARDOT_ACTIONS.REPORT_RENEWAL_USED, Util.PARDOT_ACTIONS.PLACEHOLDER_METHOD)()
        .then(() => { this.setPardotAvailability(true); })
        .catch(() => { this.setPardotAvailability(false); });
    }
  }

  @Watch('app.isPeInProgress')
  private onPeCompleted(value: boolean) {
    if (value === false) {
      this.$nextTick(() => {
        this.processDeclarationFactsFunc();
      });
    }
  }

}
